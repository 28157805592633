<template>
    <div style="width: 100%; overflow: hidden; position: relative;min-height: 600px" id="parent">
        <div class="right_content_parent">
            <div class="right_content">
                <div
                    v-show="nowIndex == 1"
                >
                    <div style="font-size: 20px;color: #333;text-align: center">科技型中小企业评测系统</div>
                    <div style="font-size: 14px;color: #FFA004;margin: 12px 0 30px;text-align: center">（为保证评测结果精准，请详细填写并保证数据真实有效）</div>
                    <div style="height: 70px;width: 80%;margin-left:50%;transform: translateX(-50%);display: flex;align-items: center;margin-bottom: 20px">
                        <Steps :current="current">
                            <Step title="填写基本信息"></Step>
                            <Step title="选择企业资质"></Step>
                            <Step title="提交生成测评报告"></Step>
                        </Steps>
                    </div>
                    <div v-show="current == 0">
                        <div style="width: 90%;margin-left: 50%;transform: translateX(-50%);">
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        企业注册地：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Select v-model="provinceCode" :transfer="transfer"
                                                style="width: 30%!important;"
                                                @on-change="getNewProvinceT">
                                            <Option :key="index" v-for="(value,key,index) in provinceData" :value="key">{{
                                                value }}
                                            </Option>
                                        </Select>&nbsp;&nbsp;省
                                        <Select v-model="cityCode" :transfer="transfer"
                                                style="width: 30%!important;"
                                                @on-change="getCity">
                                            <Option :key="index" v-for="(value,key,index) in cityData" :value="key">{{
                                                value }}
                                            </Option>
                                        </Select>&nbsp;&nbsp;市
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        职工总数：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                            v-model="baseForm.workForce"
                                            placeholder='请输入职工总数'
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        科技人员总数：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                            v-model="baseForm.technicalStaff"
                                            placeholder='请输入科技人员数'
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        上年度销售收入总额：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                            v-model="baseForm.sellingExpenses"
                                            placeholder='请输入上年度销售收入总额（万元）'
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        上年度研发费用总额：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                            v-model="baseForm.rdExpenses"
                                            placeholder='请输入上年度研发费用总额（万元）'
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        上年度成本费用支出总额：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                            v-model="baseForm.costExpenses"
                                            placeholder='请输入上年度成本费用支出总额（万元）'
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div class="table_div">
                                        上年度资产总额：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                            v-model="baseForm.totalAssets"
                                            placeholder='请输入上年度资产总额（万元）'
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                        class="table_div"
                                    >
                                        有效Ⅰ类知识产权数量：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                            v-model="baseForm.ipICount"
                                            placeholder='请输入有效Ⅰ类知识产权数量'
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="10">
                                    <div
                                        class="table_div"
                                    >
                                        有效Ⅱ类知识产权数量：
                                    </div>
                                </Col>
                                <Col span="14">
                                    <div class="table_div table_div_two">
                                        <Input
                                            v-model="baseForm.ipIiCount"
                                            placeholder='请输入有效Ⅱ类知识产权数量'
                                        ></Input>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                            style="height: 180px; border-top: none; margin-top: 50px"
                            class="next_style common_liucheng;"
                        >
                            <div
                                style="height: 40px; width: 120px"
                                class="button_style"
                                @click="Next()"
                            >
                                下一步
                            </div>
                        </div>
                    </div>

                    <div v-show="current == 1">
                        <div style="width: 90%;margin-left: 50%;transform: translateX(-50%);">
                            <div class="two-title two-row">您的企业是否满足以下条件（可多选）</div>
                            <CheckboxGroup v-model="secondForm" @on-change="delAll('Z')">
                                <Checkbox label="A"
                                          style="font-size: 16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin: 14px 0 14px 30%">
                                    <span class="hidden_span" title="拥有在有效期内高新技术企业资格证书">拥有在有效期内高新技术企业资格证书</span>
                                </Checkbox>
                                <Checkbox label="B"
                                          style="font-size: 16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin: 14px 0 14px 30%">
                                    <span class="hidden_span" title="近五年内获得过国家级科技奖励，并在获奖单位中排在前三名">近五年内获得过国家级科技奖励，并在获奖单位中排在前三名</span>
                                </Checkbox>
                                <Checkbox label="C"
                                          style="font-size: 16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin: 14px 0 14px 30%">
                                    <span class="hidden_span" title="拥有经认定的省部级以上研发机构">拥有经认定的省部级以上研发机构</span>
                                </Checkbox>
                                <Checkbox label="D"
                                          style="font-size: 16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin: 14px 0 14px 30%">
                                    <span class="hidden_span"
                                          title="近五年内主导制定过国际标准、国家标准或行业标准">近五年内主导制定过国际标准、国家标准或行业标准</span>
                                </Checkbox>
                                <Checkbox label="Z"
                                          style="font-size: 16px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;margin: 14px 0 14px 30%">
                                    <span class="hidden_span" title="以上都没有">以上都没有</span>
                                </Checkbox>
                            </CheckboxGroup>
                        </div>
                        <div
                            style="height: 180px; border-top: none; margin-top: 50px"
                            class="next_style common_liucheng;"
                        >
                            <div
                                style="height: 40px; width: 120px"
                                class="button_style"
                                @click="current = 0"
                            >
                                上一步
                            </div>
                            <div
                                style="height: 40px; width: 120px; margin-left: 20px"
                                class="button_style"
                                @click="submitForm()"
                            >
                                提交
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="nowIndex == 0" style="height: auto">
                    <div style="display: flex;justify-content: center;height: 70px;line-height: 70px;background-color: #1890FF;border: 1px solid #1890FF;color: white;font-size: 20px;">
                            <span>{{ reportList.userName }}</span
                            >&nbsp;&nbsp;
                        <span style="margin-left: 20px"
                        >科技型中小企业测评报告
                        <span>({{ reportList.createTime }})</span></span>
                    </div>
                    <div style="height:auto; border: 1px solid rgb(218, 226, 229)">
                        <div style="padding-top: 70px" class="div_center">
                            <Row :gutter="16">
                                <Col span="12">
                                    <div style="height: 220px">
                                        <div style="height: 44px; line-height: 44px">
                                            <span style=" width: 126px;display:inline-block;text-align: right;font-size: 14px;padding-right: 10px;">企业科技人员占比</span>
                                            <i-progress :percent="Number(datasplit(reportList.technicalStaff)[3])">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1890FF">{{ datasplit(reportList.technicalStaff)[3] }}分</span>
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                                            <span style="width: 126px;display: inline-block;text-align: right;font-size: 14px;padding-right: 10px;">企业研发投入指标</span>
                                            <i-progress :percent="Number(datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[3]:datasplit(reportList.sellingExpenses)[5])">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1890FF"
                                                >{{ datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[3]:datasplit(reportList.sellingExpenses)[5]}}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                                            <span style="width: 126px; display: inline-block;text-align: right;font-size: 14px;padding-right: 10px;">企业科技成果指标</span>
                                            <i-progress
                                                    :percent="Number(datasplit(reportList.ipICount)[2]>datasplit(reportList.ipIiCount)[2]?datasplit(reportList.ipICount )[2] : datasplit(reportList.ipIiCount )[2])">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1890FF">{{ datasplit(reportList.ipICount)[2]>datasplit(reportList.ipIiCount)[2]?datasplit(reportList.ipICount )[2] : datasplit(reportList.ipIiCount )[2] }}分</span>
                                            </i-progress>
                                        </div>
                                    </div>
                                </Col>
                                <Col span="12">
                                    <div style="font-size: 14px;height: 120px;border: 1px solid rgb(218, 226, 229);border-right: none;border-bottom: none;">
                                        <Row>
                                            <Col span="6">
                                                <div class="table-box" style="height: 120px;line-height: 120px">
                                                    测评总得分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box-double" style="height: 120px;">
                                                    <span style="color: #FDA736;font-size: 14px"> 自评得分
                                                        <b style="font-size: 32px">{{ reportList.evalScore||0 }}</b>分</span>
                                                    <div style="font-size: 12px;line-height: 20px;text-align: left;white-space: normal;">
                                                        <div v-if="!reportList.evalResult ">
                                                            <Icon type="md-close-circle" color="#FF6160"/>
                                                            很遗憾，企业基本条件/科技人员占比不符合科技型中小企业申报标准
                                                        </div>
                                                        <div v-else>
                                                            <Icon type="md-checkmark-circle" color="#36C2CF"/>
                                                            恭喜，企业各项指标数据良好，符合科技型中小企业申报标准
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="padding: 50px 0" class="div_center">
                            <div style="font-size: 14px;border: 1px solid rgb(218, 226, 229);border-right: none;border-bottom: none;">
                                <Row>
                                    <Col span="8">
                                        <div class="table-box"
                                             style="height: 50px;text-align: center;"
                                             title="科技型中小企业认证基本条件达成情况">
                                            科技型中小企业认证基本条件达成情况
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">企业情况</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">认定标准</div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table-box-double" style="height: 50px">结果</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table-box" style="height: 50px; text-align: center">企业注册地</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.placeOfRegistration)[0] }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.placeOfRegistration)[1] }}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.placeOfRegistration)[2] }}
                                        </div>
                                    </Col>

                                    <Col span="8">
                                        <div class="table-box" style="height: 50px; text-align: center">职工人数</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.workForce)[0] }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.workForce)[1] }}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.workForce)[2] }}
                                        </div>
                                    </Col>

                                    <Col span="8">
                                        <div class="table-box" style="height: 50px; text-align: center">上年度销售收入</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.sellingExpenses)[0]
                                            }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.sellingExpenses)[1]
                                            }}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.sellingExpenses)[2]
                                            }}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div style="padding: 50px 0" class="div_center">
                            <div style="font-size: 14px;border: 1px solid rgb(218, 226, 229);border-right: none;border-bottom: none;">
                                <Row>
                                    <Col span="8">
                                        <div class="table-box"
                                             style="height: 50px;text-align: center;"
                                             title="科技型中小企业认证具体得分项">
                                            科技型中小企业认证具体得分项
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">结果</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">评分区间</div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table-box-double" style="height: 50px">得分</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="8">
                                        <div class="table-box" style="height: 50px; text-align: center">科技人员占企业职工总数占比
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{parseInt(datasplit(reportList.technicalStaff)[1] * 100)}} %
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{datasplit(reportList.technicalStaff)[2] }}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table-box-double" style="height: 50px">
                                            {{datasplit(reportList.technicalStaff)[3] }}
                                        </div>
                                    </Col>

                                    <Col span="8">
                                        <div class="table-box" style="height: 50px; text-align: center">企业研发投入指标</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{parseInt((datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[1]:datasplit(reportList.sellingExpenses)[3])*100)}}%
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[2]:datasplit(reportList.sellingExpenses)[4]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table-box-double" style="height: 50px">
                                            {{datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[3]:datasplit(reportList.sellingExpenses)[5]}}
                                        </div>
                                    </Col>

                                    <Col span="8">
                                        <div class="table-box"
                                             style="height: 60px;line-height: 60px; text-align: center">企业科技成果指标
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 60px;line-height: 30px">
                                            I类知识产权数量{{ datasplit(reportList.ipICount)[0] }}项<br>II类知识产权{{
                                            datasplit(reportList.ipIiCount)[0] }}项
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 60px;line-height: 60px"
                                             v-if="datasplit(reportList.ipICount)[2] > datasplit(reportList.ipIiCount)[2]">
                                            {{ datasplit(reportList.ipICount)[1]}}
                                        </div>
                                        <div class="table-box-double" style="height: 60px;line-height: 60px" v-else>
                                            {{ datasplit(reportList.ipIiCount)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="4">
                                        <div class="table-box-double" style="height: 60px;line-height: 60px"
                                             v-if="datasplit(reportList.ipICount)[2] > datasplit(reportList.ipIiCount)[2]">
                                            {{ datasplit(reportList.ipICount)[2]}}
                                        </div>
                                        <div class="table-box-double" style="height: 60px;line-height: 60px" v-else>
                                            {{ datasplit(reportList.ipIiCount)[2]}}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div style="height: 180px; border-top: none" class="next_style common_liucheng;">
                            <div style="height: 40px; width: 120px;display: none" class="button_style">下载报告</div>
                            <div @click="reTest()" style="height: 40px;width: 120px;margin-left: 20px;background-color: #1890FF;" class="button_style">重新测评
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import $ from "jquery";
    import {
        confirmBase,
        getReportInfo,
        confirmStageBase
    } from "@/plugins/api/EnterpriseScienceExam";
    import {
        getNewProvince,
        getByParentCode,
        getEnterpriseInfo
    } from "@/plugins/api/EnterpriseExam";
    import {
        isNumber,
        warnToast,
        errorToast,
    } from "@/plugins/tools/util";

    export default {
        name: "EnterpriseScienceExamination",
        mounted() {
            this.getUserInfo()
        },
        computed: {
            ...mapGetters({
                userName: "userStore/userName",
                userId: "userStore/userId",
            }),
        },
        data() {
            return {
                cityData: {},
                cityName: '',
                cityCode: '',
                provinceData: {"": ''},
                provinceName: '',
                provinceCode: '',
                thisYear: "",
                evalId: "",
                transfer: true,
                reportList: [],
                userInfo: [],
                // 第一步from
                baseForm: {
                    costExpenses: "", //成本费用(用;分割，一共四部分，1.成本金额 2.成本金额占比 3.评分区间 4.得分) ,
                    id: 0, //自增id
                    ipICount: "", //I类知产数量(用;分割，一共三部分，1.I类知产数量 2.评分区间 3.得分) ,
                    ipIiCount: "", //II类知产数量(用;分割，一共三部分，1.II类知产数量 2.评分区间 3.得分) ,
                    isMeetTheConditions: "", //是否满足以下条件（用;分割，0不符合，1符合）例：0;0;0;0;1 ,
                    placeOfRegistration: "", //企业注册地(用;分割，一共三部分，1.企业注册地 2.认定标准 3.结果)例：江苏南京;中国境内;符合 ,
                    rdExpenses: "", //研发费用 ,
                    sellingExpenses: "", //销售费用(用;分割，一共六部分，1.销售金额 2.认定标准 3.结果 4.销售金额占比 5.评分区间 6.得分 ) ,
                    technicalStaff: "", //科技职工总数(用;分割，一共四部分，1.科技职工总数 2.科技职工占比 3.评分区间 4.得分) ,
                    totalAssets: "", //资产总额(用;分割，一共三部分，1.资产总额 2.认定标准 3.结果) ,
                    workForce: "" //职工总数(用;分割，一共三部分，1.职工总数 2.认定标准 3.结果)
                },
                secondForm: [],
                nowIndex: 1,
                current: 0,
                columns1: [
                    {
                        title: "国家高新技术企业认证具体得分情况",
                        key: "itemCondition",
                        width: "600px",
                        className: "demo-table-info-column",
                    },
                    {
                        title: "国家高新技术企业认证标准",
                        key: "countryEval",
                    },
                    {
                        title: "江苏省国企入库标准",
                        key: "provinceEval",
                    },
                    {
                        title: "南京市国高企入库标准",
                        key: "cityEval",
                    },
                ],
            };
        },
        methods: {

            delAll(name) {
                if (this.secondForm.indexOf(name) > -1) {
                    this.secondForm = [name]
                }
                let isMeetTheConditions = [0, 0, 0, 0, 0]
                let checklist = ['A', 'B', 'C', 'D', 'Z']
                for (let i in checklist) {
                    if (this.secondForm.indexOf(checklist[i]) > -1) {
                        isMeetTheConditions[i] = 1
                    } else {
                        isMeetTheConditions[i] = 0
                    }
                }
                this.baseForm.isMeetTheConditions = isMeetTheConditions.join(';')
            },
            getRegisterTime(starTime) {
                this.baseForm.registerTime = starTime;
            },
            async getUserInfo() {
                getEnterpriseInfo().then((res) => {
                    if (res.code == 200) {
                        this.userInfo = res.data
                        this.getReportFun()
                    }
                });
            },
            // 得到报告
            async getReportFun() {
                let param = {
                    id: 0,
                    status: '-1',
                    userName: this.userInfo.enterpriseName
                }
                const json = await getReportInfo(param)
                if (json.code == 200 && json.data.list && json.data.list.length > 0) {
                    if (json.data.list[0].status == 1) {
                        this.nowIndex = 0
                        this.reportList = json.data.list[0]
                        this.reportList.createTime = this.reportList.createTime.split('T')[0]
                        // this.showReport()
                    } else {
                        this.reportList = json.data.list[0]
                        this.reportList.createTime = this.reportList.createTime.split('T')[0]
                        for (let item in this.baseForm) {
                            this.baseForm[item] = json.data.list[0][item]
                        }
                        if (this.baseForm.placeOfRegistration) {
                            this.provinceName = this.baseForm.placeOfRegistration.split('/')[0] // this.baseForm.placeOfRegistration.substr(0, 3)
                            this.cityName = this.baseForm.placeOfRegistration.split('/')[1] // this.baseForm.placeOfRegistration.substring(3)
                            console.log(this.provinceName, this.cityName)
                        }
                    }
                } else {
                    this.reTest()
                }
                getNewProvince().then((res) => {
                    if (res.code == 200) {
                        this.provinceData = res.data
                        this.provinceCode = '' // Object.keys(this.provinceData)[0]
                        const provinceArr = Object.keys(this.provinceData).map(x => ({
                            key: x,
                            value: res.data[x]
                        }))
                        if (this.baseForm.placeOfRegistration) {
                            this.provinceCode = provinceArr.filter(x => x.value == this.baseForm.placeOfRegistration.split('/')[0])[0].key
                            getByParentCode({parentCode: this.provinceCode}).then((rres) => {
                                if (rres.code == 200) {
                                    this.cityData = rres.data;
                                    for (let k in rres.data) {
                                        if (rres.data[k] == this.cityName) {
                                            this.cityCode = k
                                            console.log(this.cityCode, this.cityName)
                                            break
                                        }
                                    }
                                }
                            })
                        }
                    }
                })
                let date = new Date();
                this.thisYear = date.getFullYear();
            },
            async Next() {
                for (let item in this.baseForm) {
                    if (this.baseForm[item] === "" && item != 'id' && item != 'isMeetTheConditions') {
                        warnToast("请将数据填写完成");
                        return;
                    } else {
                        if (item != 'placeOfRegistration' && item != 'isMeetTheConditions' && isNumber(this.baseForm[item]) == false) {
                            warnToast("请输入数字" + item)
                            return
                        }
                    }
                }
                let json = await confirmStageBase(this.baseForm);
                if (json.code == "200") {
                    this.baseForm.id = json.data;
                    this.current += 1;
                    this.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message)
                }
            },
            async submitForm() {
                if (this.baseForm.isMeetTheConditions === "" || this.baseForm.isMeetTheConditions === "0;0;0;0;0") {
                    warnToast("请将数据填写完成");
                    return;
                }
                let json = await confirmBase(this.baseForm);
                if (json.code == "200") {
                    this.nowIndex = 0;
                    this.reportList = json.data
                    this.reportList.createTime = this.reportList.createTime.split('T')[0]
                    this.changeHeight();
                    document.body.scrollTop = 0
                    document.documentElement.scrollTop = 0
                } else {
                    errorToast(json.message)
                }
            },
            reTest() {
                this.nowIndex = 1;
                this.current = 0;
                this.changeHeight();
                this.baseForm = {
                    costExpenses: "", //成本费用(用;分割，一共四部分，1.成本金额 2.成本金额占比 3.评分区间 4.得分) ,
                    id: 0, //自增id
                    ipICount: "", //I类知产数量(用;分割，一共三部分，1.I类知产数量 2.评分区间 3.得分) ,
                    ipIiCount: "", //II类知产数量(用;分割，一共三部分，1.II类知产数量 2.评分区间 3.得分) ,
                    isMeetTheConditions: "", //是否满足以下条件（用;分割，0不符合，1符合）例：0;0;0;0;1 ,
                    placeOfRegistration: "", //企业注册地(用;分割，一共三部分，1.企业注册地 2.认定标准 3.结果)例：江苏南京;中国境内;符合 ,
                    rdExpenses: "", //研发费用 ,
                    sellingExpenses: "", //销售费用(用;分割，一共六部分，1.销售金额 2.认定标准 3.结果 4.销售金额占比 5.评分区间 6.得分 ) ,
                    technicalStaff: "", //科技职工总数(用;分割，一共四部分，1.科技职工总数 2.科技职工占比 3.评分区间 4.得分) ,
                    totalAssets: "", //资产总额(用;分割，一共三部分，1.资产总额 2.认定标准 3.结果) ,
                    workForce: "" //职工总数(用;分割，一共三部分，1.职工总数 2.认定标准 3.结果)
                }
                document.body.scrollTop = 0
                document.documentElement.scrollTop = 0

                getNewProvince().then((res) => {
                    if (res.code == 200) {
                        this.provinceData = res.data
                        this.getNewProvinceT(Object.keys(this.provinceData)[0])
                    }
                })
                let date = new Date();
                this.thisYear = date.getFullYear();
            },
            pre() {
                this.current -= 1;
            },
            getNewProvinceT(item) {
                console.log('item', item)
                this.provinceName = this.provinceData[item]
                this.provinceCode = item
                getByParentCode({parentCode: item}).then((res) => {
                    if (res.code == 200) {
                        this.cityData = res.data;
                        this.baseForm.placeOfRegistration = ''
                        this.cityName = ''
                    }
                })
            },
            getCity(item) {
                this.cityName = this.cityData[item]
                this.cityCode = item
                this.baseForm.placeOfRegistration = this.provinceName + '/' + this.cityName
            },
            showTab(index) {
                this.$router.push({
                    path: this.tab_list[index].path
                })
            },
            changeHeight() {
                let that = this;
                this.$nextTick(() => {
                    that.tab_height =
                        Number($(".right_content_parent").height() + 190) -
                        (Number(document.body.clientHeight) - 101) >
                        20
                            ? $(".right_content_parent").height() + 200 + "px"
                            : Number(document.body.clientHeight) - 101 + "px";
                });
            },
            datasplit(arr) {
                if (arr) {
                    return arr.split(';')
                } else {
                    return ''
                }
            }
        },
    };
</script>

<style scoped lang="scss">

.yi_hang_sheng {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.table_div {
  height: 60px;
  line-height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.table_div_two {
  border-right: none !important;
  justify-content: flex-start;
}

.arrow_ld {
  color: white !important;
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  line-height: 14px;
  text-align: center;
}

.div_first_td {
  color: white;
}

.table-box {
  width: 100%;
  height: 55px;
  line-height: 55px;
  text-align: left;
  border-bottom: 1px solid rgb(218, 226, 229);
  border-right: 1px solid rgb(218, 226, 229);
  padding: 0 10px;
  overflow: hidden;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table-box2 {
  width: 100%;
  height: 55px;
  line-height: 55px;
  text-align: left;
  border-bottom: 1px solid rgb(218, 226, 229);
  border-right: 1px solid rgb(218, 226, 229);
  font-size: 14px;
}

.table-box-double {
  width: 100%;
  height: 55px;
  line-height: 55px;
  text-align: center;
  border-bottom: 1px solid rgb(218, 226, 229);
  border-right: 1px solid rgb(218, 226, 229);
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 10px;
}

.ivu-progress {
  display: inline-block !important;
  width: 65% !important;
}

.div_center {
  width: 95%;
  margin-left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted rgb(218, 226, 229);
}

.neirong {
  margin-left: 130px;

  .download_span {
    color: blue;
    display: inline-block;
    margin-right: 30px;
  }

  p {
    display: inline-block;
    margin-bottom: 20px;
    font-size: 14px;
    color: black;
  }
}

.bottom_style {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: -110px;
}

.common_liucheng:nth-child(1) {
  border: none;
}

.common_liucheng {
  border-top: 2px dotted #f5f5f5;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  & > span {
    display: block;
    padding-left: 5px;
    padding-top: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    color: rgb(51, 51, 51);
    margin-bottom: 60px;
  }
}

.next_style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_real_content {
  margin-bottom: 50px;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 40px;
  overflow: hidden;
}

.right_header {
  width: 95%;
  padding-top: 20px;
  height: 140px;
  position: relative;
  margin-bottom: 40px !important;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px dotted #f5f5f5;
  overflow: hidden;

  span {
    display: block;
    margin-bottom: 20px;
  }
}

.button_style {
  display: inline-block;
  border-radius: 2px;
  background-color: #1890FF;
  width: 60px;
  height: 40px;
  z-index: 51;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  color: white;
  cursor: pointer;
}

.right_content {
  width: 90%;
  /*border-width: 1px;*/
  /*border-color: rgb(218, 226, 229);*/
  /*border-style: solid;*/
  background-color: rgb(255, 255, 255);
  min-width: 900px;
  height: auto;
}

.right_content_parent {
  box-sizing: border-box;
  width: 80%;
  min-width: 960px;
  float: left;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  position: relative;
  margin-bottom: 130px;
}

.left_tab_parent {
  float: left;
  // min-width: 160px;
  width: 160px;
  background-color: #D5E7FA;
  .left_tab {
    div {
      height: 61px;
      line-height: 61px;
      font-size: 14px;
      color: #597697;
      padding-left: 20px;
      cursor: pointer;
      border-bottom: 1px solid #BDDBFA;
      &.isChoose {
        color: #fff;
        background-color: #1767E0;
      }
    }
  }
}

.hidden_span {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  white-space: pre-wrap;
  line-height: normal;
  margin-left: 10px;
  color: #333333;
}

.two-title {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 0 auto;
  font-weight: bold;
}

.two-row {
  width: 500px;
  height: 16px;
  line-height: 16px;
  margin: 14px 0 14px 30%;
  display: inline-block;
}

.two-item {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #333333;
}
/deep/ .ivu-input {
    width: 360px;
    height: 32px !important;
    background: #FFFFFF;
    border-radius: 4px !important;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 0 10px;
}
/deep/ .ivu-select-selection {
    border-radius: 4px !important;
}
</style>
